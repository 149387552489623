import React, { forwardRef, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AuthContext } from "../../../../contexts/AuthContext";
import Avatar from "../../../Avatar/Avatar";
import BurgerMenuIcon from "./BurgerMenuIcon";

const ToggleButton = forwardRef(({ className, onClick, user }, ref) => {
  const { userActionsCount } = useContext(AuthContext);

  const notifications = userActionsCount?.totalUserActions;

  return (
    <span data-testid="AppHeaderUserMenu--loggedId" ref={ref}>
      {user && (
        <button
          className={classNames(
            "AppHeaderUserMenu-ToggleButton-desktop",
            className,
          )}
          onClick={onClick}
        >
          <Avatar name={user.firstName} notifications={notifications} />
          <BurgerMenuIcon />
        </button>
      )}

      <button
        className={classNames(
          "AppHeaderUserMenu-ToggleButton-mobile",
          { "AppHeaderUserMenu-ToggleButton-mobile--loggedIn": !!user },
          className,
        )}
        aria-label="Menu"
        onClick={onClick}
      >
        <BurgerMenuIcon />
        {notifications > 0 && (
          <span className="AppHeaderUserMenu-notifications">
            {notifications > 9 ? "9+" : notifications}
          </span>
        )}
      </button>
    </span>
  );
});

ToggleButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default ToggleButton;
