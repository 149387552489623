import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DateRangePickerTrigger from "../../../../../components/DateRangePicker/DateRangePickerTrigger/DateRangePickerTrigger";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import { isDateBlocked } from "../../../../../utils/fromToDatesValidation";
import { useCurrencies } from "../../../context/CurrenciesContext";

const dateInput = PropTypes.shape({
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
});

const propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string,
  listingPrice: PropTypes.number,
  currency: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  datesAvailable: PropTypes.bool,
  multilisting: PropTypes.bool,
  canListingBeRequested: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    reason: PropTypes.oneOf(["LISTING_ALREADY_REQUESTED"]),
    requesting: PropTypes.bool.isRequired,
  }),
  from: dateInput,
  to: dateInput,
  minBookingDuration: PropTypes.number,
  maxBookingDuration: PropTypes.number,
  minDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxYear: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  blockedDurations: PropTypes.array,
  available: PropTypes.bool,
};

const MobileCallOut = forwardRef(
  (
    {
      t,
      lang,
      listingPrice,
      currency,
      onClick,
      loading,
      datesAvailable,
      multilisting,
      canListingBeRequested,
      from,
      to,
      minBookingDuration,
      maxBookingDuration,
      minDate,
      maxDate,
      maxYear,
      blockedDurations,
      available,
    },
    ref,
  ) => {
    const mobileBookingButtonRef = useRef();
    const showDescription = !multilisting || (multilisting && datesAvailable);

    const { formatCurrency } = useCurrencies();

    const getButtonText = () => {
      if (
        canListingBeRequested.status === false &&
        canListingBeRequested.reason === "LISTING_ALREADY_REQUESTED"
      ) {
        return t(
          "spages.listing.ListingDetailsPageBase.MobileCallOut.alreadyRequested",
        );
      }

      if (multilisting) {
        if (datesAvailable === false) {
          return t(
            "spages.multilisting.MultilistingDetailsPage.form.changeDates",
          );
        }

        if (datesAvailable === true) {
          return t("spages.listing.ListingDetailsPageBase.MobileCallOut.CTA");
        }

        return t(
          "spages.multilisting.MultilistingDetailsPage.form.checkAvailability",
        );
      }

      return t("spages.listing.ListingDetailsPageBase.MobileCallOut.CTA");
    };

    return (
      <aside className="ListingDetailsPage-mobileCallOut">
        <div>
          <div className="ListingDetailsPage-mobileCallOut-info">
            <div className="ListingDetailsPage-mobileCallOut-price">
              {formatCurrency({ amount: listingPrice, currency, lang })}
              <span className="ListingDetailsPage-mobileCallOut-price-descriptor">
                /{t("month")}
              </span>
            </div>

            <div data-testid="MobileCallOut-DateRangePicker">
              <DateRangePickerTrigger
                fromDate={from.value}
                toDate={to.value}
                ref={ref}
                onChange={({ fromDate, toDate }) => {
                  from.onChange(fromDate);
                  to.onChange(toDate);
                }}
                showFlexibleDates={false}
                minBookingDuration={minBookingDuration || 1}
                maxBookingDuration={maxBookingDuration}
                minDate={minDate}
                maxDate={maxDate}
                maxYear={maxYear}
                blockedDurations={blockedDurations}
                isDayBlocked={(date) =>
                  isDateBlocked({
                    date,
                    blockedDurations,
                    available,
                  })
                }
                showApplyButton
                applyButtonType="button"
                onApplyButtonClick={() =>
                  mobileBookingButtonRef?.current?.focus()
                }
                isMobile
                isFullDateFormat={false}
                dateRangeSeparator="-"
                triggerClassName="ListingDetailsPageMobileCallOut-trigger"
                previewButtonClassName="ListingDetailsPageMobileCallOut-previewDateButton"
                dateCtaPanelClassName="ListingDetailsPageMobileCallOut-ctaPanel"
              />
            </div>
          </div>

          <div className="ListingDetailsPage-mobileCallOut-buttonOuter">
            <Button
              disabled={loading || canListingBeRequested.status === false}
              loading={loading}
              onClick={onClick}
              colorVariant="primary"
              dataTestId="ListingDetailsPage-mobileCallOut-button"
              size="medium"
              ref={mobileBookingButtonRef}
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
        {showDescription && (
          <div className="ListingDetailsPage-mobileCallOut-info-legend">
            {t(
              "spages.listing.ListingDetailsPageBase.MobileCallOut.priceDescription",
            )}
          </div>
        )}
      </aside>
    );
  },
);

MobileCallOut.propTypes = propTypes;
export default MobileCallOut;
