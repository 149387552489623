import cookie from "js-cookie";
import get from "lodash/get";
import pick from "lodash/pick";
import Rollbar from "rollbar";
import smoothscroll from "smoothscroll-polyfill";
import packageJson from "../../package.json";
import analytics from "../analytics";
import createApiClient from "../api";
import initAmplitudeTracker from "../tracker/amplitude";
import { registerCookieConstentListeners } from "../tracker/cf-utils";
import * as tracker from "../tracker/tracker";
import "../utils/dayjs";
import dataLayerUtils from "../utils/data-layer";
import { create as createLocalizer } from "../utils/localizer";
import scrollbarSize from "../utils/scrollbarSize";
import { urlMaker } from "../utils/url";
import getWFCONFIG from "../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

smoothscroll.polyfill();

const codeVersion = packageJson.version;

export default function bootstrapClient(LOCALE_DATA, APP_DATA, cb) {
  dataLayerUtils.populateDataLayerFromPersisted();
  const visitorId = cookie.get("visitorId");
  const sessionId = cookie.get("sessionId");
  const hostName = new URL(document.location).hostname;

  /* eslint-disable no-new */
  const rollbar = new Rollbar({
    accessToken: WF_CONFIG.ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: WF_CONFIG.ROLLBAR_ENABLED === "true",
    payload: {
      environment: WF_CONFIG.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: codeVersion,
        },
      },
      person: {
        ...pick(APP_DATA.user, ["_id", "landlord", "language"]),
        id: get(APP_DATA.user, "_id", visitorId),
        visitorId,
        sessionId,
      },
      isImpersonatedByCRMUser: cookie.get("tiramisu"),
      cookies: {
        "listing-filter": cookie.get("listing-filter"),
      },
    },
    verbose: true,
    hostSafeList: [hostName],
    itemsPerMinute: 5,
    checkIgnore,
  });

  window.rollbar = rollbar;

  // wait for analytics to be ready
  analytics.onReady(() => {
    analytics.init();
  });

  analytics.getClientId((clientId) => {
    rollbar.options.payload.person.gaClientId = clientId;
  });

  analytics.getSessionId((sessionId) => {
    rollbar.options.payload.person.gaSessionId = sessionId;
  });

  tracker.init();
  initAmplitudeTracker();
  registerCookieConstentListeners(tracker);

  function checkIgnore() {
    return (
      document.querySelector("html").classList.contains("lt-ie10") ||
      isIE10() ||
      isAndroidDefaultBrowser() ||
      isCloudFlareBot() ||
      noIntlSupport()
    );

    function isIE10() {
      return navigator.appVersion.indexOf("MSIE 10") !== -1;
    }

    function noIntlSupport() {
      return !window.Intl;
    }

    function isAndroidDefaultBrowser() {
      const ua = navigator.userAgent;
      return (
        ua.indexOf("Mozilla/5.0") > -1 &&
        ua.indexOf("Android ") > -1 &&
        ua.indexOf("AppleWebKit") > -1 &&
        !(ua.indexOf("Chrome") > -1)
      );
    }

    function isCloudFlareBot() {
      // UA: Mozilla/5.0 (compatible; CloudFlare-AlwaysOnline/1.0; +http://www.cloudflare.com/always-online) AppleWebKit/534.34
      const ua = navigator.userAgent;

      return ua.indexOf("CloudFlare-AlwaysOnline") > -1;
    }
  }
  /* eslint-enable no-new */

  // Create API Client and hook it up to cookies
  const api = createApiClient({
    baseURL: "/",
    apiURL: WF_CONFIG.PROXY_URL,
    authToken: cookie.get("authToken"),
    tiramisu: cookie.get("tiramisu"),
    sessionId: cookie.get("sessionId"),
    referredBy: cookie.get("referredBy"),
  });

  // Create localizer instance that will be used by all components
  const localizer = createLocalizer(LOCALE_DATA.strings, LOCALE_DATA.locale);
  const url = urlMaker(LOCALE_DATA.locale);

  // Detect touch support
  const touchsupport =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
  if (!touchsupport) {
    // browser doesn't support touch
    document.documentElement.className += " non-touch";
  } else {
    document.documentElement.className += " touch";
  }

  // Set scrollbar size as a css custom property
  const scrollbarWidth = scrollbarSize().width;
  document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

  cb({
    analytics,
    api,
    currentUrl: APP_DATA.currentUrl,
    user: APP_DATA.user,
    translationUrls: APP_DATA.translationUrls,
    localizer,
    featureFlags: APP_DATA.featureFlags || [],
    url,
    tracker,
    currencies: APP_DATA.currencies,
  });
}
