/* eslint-disable import/first */
import "babel-regenerator-runtime";

import axios from "axios";
import { canUseDOM } from "../utils/dom";
import analyticsApi from "./analyticsApi";
import authApi from "./authApi";
import bankAccountsApi from "./bankAccountsApi";
import bookingOfferApi from "./bookingOfferApi";
import bookingRequestsApi from "./bookingRequestsApi";
import bookingsApi from "./bookingsApi";
import categoriesApi from "./categoriesApi";
import costCalculationApi from "./costCalculationApi";
import currenciesApi from "./currenciesApi";
import documentsApi from "./documentsApi";
import emailSubscriptionTypesApi from "./emailSubscriptionTypesApi";
import featureFlagsApi from "./featureFlags";
import filesApi from "./filesApi";
import filtersApi from "./filtersApi";
import gdprApi from "./gdprApi";
import imagesApi from "./imagesApi";
import listingRequestsApi from "./listingRequestsApi";
import listingsApi from "./listingsApi";
import miscellaneousApi from "./miscellaneousApi";
import multilistingsApi from "./multilistingsApi";
import municipalitiesApi from "./municipalitiesApi";
import referralsApi from "./referralsApi";
import regionsApi from "./regionsApi";
import shortlistApi from "./shortlistApi";
import smsNotificationsApi from "./smsNotificationsApi";
import staysApi from "./staysApi";
import usersApi from "./usersApi";

/**
 * "tiramisu" is a HTTP header to signal that the current logged in user is a CRM user
 */
export default function createApiClient({
  baseURL,
  apiURL,
  authToken = "",
  accessToken = "",
  tiramisu = null,
  sessionId = "",
  referredBy = "",
  rawCookieHeader = "",
  cloudflareConnectingIP = null,
  uberTraceId = null,
  timeout = undefined,
}) {
  const headers = {
    "Access-Token": accessToken,
    authToken,
    sessionId,
    referredBy,
  };

  // On the server, we want to pass cookies to the API.
  // On the client, axios will refuse to set the cookie header because of security reasons.
  if (!canUseDOM() && rawCookieHeader) {
    headers.cookie = rawCookieHeader;
  }

  // Set "tiramisu" in headers if it's present.
  // If the current logged in user isn't a CRM user then we shouldn't set it.
  if (tiramisu) {
    headers.tiramisu = tiramisu;
  }

  if (cloudflareConnectingIP) {
    headers["cf-connecting-ip"] = cloudflareConnectingIP;
  }

  if (uberTraceId) {
    headers["uber-trace-id"] = uberTraceId;
  }

  const httpApi = axios.create({
    baseURL: apiURL,
    headers,
    timeout,
  });

  const httpFrontend = axios.create({
    baseURL,
    headers,
    timeout,
  });

  httpApi.interceptors.response.use((response) => response.data, onError);
  httpFrontend.interceptors.response.use((response) => response.data, onError);

  function onError(error) {
    if (error.code === "ECONNABORTED") {
      return Promise.reject({
        name: "NetworkTimeoutError",
      });
    }

    if (error.message === "Network Error") {
      return Promise.reject({
        name: "NetworkError",
      });
    }

    // unpack http errors
    if (error.response?.data?.error) {
      return Promise.reject(error.response.data.error);
    }

    return Promise.reject(error);
  }

  const documents = documentsApi(httpFrontend);
  const auth = authApi(httpApi, httpFrontend);
  const users = usersApi(httpApi, httpFrontend);
  const bookingOffers = bookingOfferApi(httpApi);
  const costCalculation = costCalculationApi(httpApi);
  const bookings = bookingsApi(httpApi);
  const listings = listingsApi(httpApi);
  const bookingRequests = bookingRequestsApi(httpApi);
  const multilistings = multilistingsApi(httpApi);
  const bankAccounts = bankAccountsApi(httpApi);
  const gdpr = gdprApi(httpApi);
  const regions = regionsApi(httpApi);
  const smsNotifications = smsNotificationsApi(httpApi);
  const referrals = referralsApi(httpApi);
  const featureFlags = featureFlagsApi(httpApi);
  const analytics = analyticsApi(httpApi);
  const shortlist = shortlistApi(httpApi);
  const miscellaneous = miscellaneousApi(httpApi, httpFrontend);
  const categories = categoriesApi(httpApi);
  const files = filesApi(httpApi);
  const stays = staysApi(httpApi);
  const images = imagesApi(httpApi);
  const listingRequests = listingRequestsApi(httpApi);
  const emailSubscriptionTypes = emailSubscriptionTypesApi(httpApi);
  const municipalities = municipalitiesApi(httpApi);
  const filters = filtersApi(httpApi);
  const currencies = currenciesApi(httpApi);

  return {
    http: httpApi,
    auth,
    users,
    costCalculation,
    bookings,
    bookingOffers,
    listings,
    bookingRequests,
    multilistings,
    bankAccounts,
    gdpr,
    regions,
    smsNotifications,
    referrals,
    featureFlags,
    analytics,
    shortlist,
    categories,
    files,
    stays,
    images,
    documents,
    listingRequests,
    emailSubscriptionTypes,
    municipalities,
    filters,
    currencies,
    ...miscellaneous,
  };
}
