import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useCurrencies } from "../../spages/spa/context/CurrenciesContext";

const propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
};

const ListingPrice = ({ t, lang, light, price, currency, className }) => {
  const { formatCurrency } = useCurrencies();

  return (
    <strong
      className={classNames(
        className,
        "ListingPrice",
        light && "ListingPrice--light",
      )}
    >
      <span className="ListingPrice-price">
        {formatCurrency({ amount: price, currency, lang })}
      </span>
      <small className="ListingPrice-time">
        {t("spages.landlord.Calculator.Price.perMonth")}
      </small>
    </strong>
  );
};

ListingPrice.propTypes = propTypes;
export default ListingPrice;
