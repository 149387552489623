import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { defaultLocale, langLocaleMap } from "../../../utils/prices";
import { getDisplayName } from "../../../utils/react-utils";
import { useI18n } from "./I18nContext";

const CurrenciesContext = createContext({
  currencies: [],
  formatCurrency: () => {},
});

export const useCurrencies = () => {
  return useContext(CurrenciesContext);
};

const providerPropTypes = {
  currencies: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export function CurrenciesContextProvider({ currencies, children }) {
  const { lang: currentLang } = useI18n();

  const formatCurrency = ({ amount, currency, lang, fixed }) => {
    if (!currency) {
      throw new Error("The 'currency' argument not provided!");
    }

    // Check if the currency we're formatting is supported
    const supportedCurrency = currencies.find(
      ({ isoCode }) => isoCode === currency,
    );

    if (!supportedCurrency) {
      throw new Error(`Currency is not supported: ${currency}`);
    }

    const formatter = new Intl.NumberFormat(
      langLocaleMap[lang ?? currentLang] || defaultLocale,
      {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: fixed ? 2 : 0,
      },
    );

    return formatter.format(amount / 10 ** supportedCurrency.fractionDigits);
  };

  return (
    <CurrenciesContext.Provider value={{ currencies, formatCurrency }}>
      {children}
    </CurrenciesContext.Provider>
  );
}

CurrenciesContextProvider.propTypes = providerPropTypes;

export function withCurrencies(Component) {
  function WrappedComponent(props) {
    const currenciesValue = useCurrencies();
    return <Component {...props} {...currenciesValue} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithCurrencies(${getDisplayName(Component)})`;

  return WrappedComponent;
}
