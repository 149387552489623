import loadable from "@loadable/component";
import { routes } from "../../../routes";
import aboutGetData from "./AboutPage/getData";
import accountPage2020GetData, {
  shouldAccountPageBeReloaded,
} from "./AccountPage2020/getData";
import getAuthPageData from "./AuthPage/getData";
import bookingsLobbyPageGetData from "./BookingsPage/LobbyPage/getData";
import requestPageGetData, {
  shouldRequestPageReload,
} from "./BookingsPage/RequestPage/getData";
import getBookingWizardData, {
  shouldBookingPageReload,
} from "./BookingWizardPage/getData";
import getExtensionDataV2 from "./BookingWizardPage/getDataExtensionPage";
import getContentPageDataFactory from "./ContentPages/getDataFactory";
import contractGetData, {
  shouldContractPageReload,
} from "./ContractPage/getData";
import createListingV2PageGetData, {
  shouldCreateListingPageV2Reload,
} from "./CreateListingV2Page/getData";
import helpCategoryGetData from "./HelpPages/HelpCategoryPage/getData";
import helpSinglePostGetData from "./HelpPages/HelpSinglePostPage/getData";
import helpSubCategoryGetData from "./HelpPages/HelpSubCategoryPage/getData";
import homePageGetData from "./HomePage/getData";
import listingDetailsPageGetData, {
  shouldListingPageReload,
} from "./ListingDetailsPage/getData";
import listingsPageGetData, {
  shouldListingsBeReloaded,
} from "./ListingsPage/getData";
import referralInvitePageGetData from "./ReferralPages/invites/getData";
import referralLandingPageGetData from "./ReferralPages/landing/getData";
import shortlistGetData from "./Shortlist/getData";
import tosPageGetData from "./TosPage/getData";

export default ({ isLandlordRegistrationFlowActive }) => {
  /**
   * Array of React Router <Route> props
   * routeName = the key in routes.jsx
   * getData = called once server side to fetch the page data
   */
  const spaRoutes = [
    {
      exact: true,
      component: loadable(
        () => import("./CreateListingV2Page/CreateListingV2Page"),
        {
          ssr: false,
        },
      ),
      routeName: "createListing",
      getData: createListingV2PageGetData,
      userIsRequired: isLandlordRegistrationFlowActive,
      shouldPageReload: shouldCreateListingPageV2Reload,
    },
    {
      exact: true,
      component: loadable(
        () => import("./CreateListingV2Page/CreateListingV2Page"),
        {
          ssr: false,
        },
      ),
      routeName: "createListingRefugee",
      getData: createListingV2PageGetData,
      userIsRequired: isLandlordRegistrationFlowActive,
      shouldPageReload: shouldCreateListingPageV2Reload,
    },
    {
      exact: true,
      component: loadable(
        () => import("./CreateListingV2Page/CreateListingV2Page"),
        {
          ssr: false,
        },
      ),
      routeName: "createImmocationListing",
      getData: createListingV2PageGetData,
      userIsRequired: isLandlordRegistrationFlowActive,
      shouldPageReload: shouldCreateListingPageV2Reload,
    },
    {
      exact: true,
      component: loadable(() => import("./HomePage/HomePage")),
      routeName: "home",
      getData: homePageGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./ListingsPage/ListingsPage/ListingsPage"),
      ),
      routeName: "listinglist",
      getData: listingsPageGetData,
      shouldPageReload: shouldListingsBeReloaded,
    },
    {
      exact: true,
      component: loadable(
        () => import("./ListingsPage/ListingsPage/ListingsPage"),
      ),
      routeName: "categories",
      getData: listingsPageGetData,
      shouldPageReload: shouldListingsBeReloaded,
    },
    {
      exact: true,
      component: loadable(() => import("./Shortlist/Shortlist")),
      routeName: "shortlist",
      getData: shortlistGetData,
    },
    {
      exact: true,
      component: loadable(() => import("./Account"), {
        ssr: false,
      }),
      routeName: "account",
      getData: accountPage2020GetData,
      userIsRequired: true,
      shouldPageReload: shouldAccountPageBeReloaded,
    },
    {
      exact: true,
      component: loadable(() => import("./ContractPage/ContractPage"), {
        ssr: false,
      }),
      routeName: "contract",
      getData: contractGetData,
      userIsRequired: true,
      shouldPageReload: shouldContractPageReload,
    },
    {
      exact: true,
      component: loadable(() => import("./AboutPage/AboutPage")),
      routeName: "about",
      getData: aboutGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./ListingDetailsPage/ListingDetailsPage"),
      ),
      routeName: "listingdetails",
      getData: listingDetailsPageGetData,
      shouldPageReload: shouldListingPageReload,
    },
    {
      exact: true,
      component: loadable(
        () => import("./ListingDetailsPage/ListingDetailsPage"),
      ),
      routeName: "multilistingdetails",
      getData: listingDetailsPageGetData,
      shouldPageReload: shouldListingPageReload,
    },
    {
      exact: true,
      component: loadable(
        () => import("./BookingWizardPage/BookingWizardPage"),
      ),
      routeName: "extensionV2",
      getData: getExtensionDataV2,
      userIsRequired: true,
    },
    {
      exact: true,
      component: loadable(
        () => import("./BookingWizardPage/BookingWizardPage"),
      ),
      routeName: "book",
      getData: getBookingWizardData,
      userIsRequired: true,
      shouldPageReload: shouldBookingPageReload,
    },
    {
      exact: true,
      component: loadable(() => import("./AuthPage/AuthPage")),
      routeName: "login",
      getData: getAuthPageData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./HelpPages/HelpCategoryPage/HelpCategoryPage"),
      ),
      routeName: "help",
      getData: helpCategoryGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./HelpPages/HelpCategoryPage/HelpCategoryPage"),
      ),
      routeName: "helpCategory",
      getData: helpCategoryGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./HelpPages/HelpSubCategoryPage/HelpSubCategoryPage"),
      ),
      routeName: "helpSubCategory",
      getData: helpSubCategoryGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./HelpPages/HelpSinglePostPage/HelpSinglePostPage"),
      ),
      routeName: "helpSinglePost",
      getData: helpSinglePostGetData,
    },
    {
      exact: true,
      component: loadable(() => import("./ContentPages/ContentPage")),
      routeName: "impressum",
      getData: getContentPageDataFactory({ contentPage: "impressum" }),
    },
    {
      exact: true,
      component: loadable(() => import("./TosPage/TosPage")),
      routeName: "tos",
      getData: tosPageGetData,
    },
    {
      exact: true,
      component: loadable(() => import("./ContentPages/ContentPage")),
      routeName: "tosUk",
      getData: getContentPageDataFactory({ contentPage: "tos-uk" }),
    },
    {
      exact: true,
      component: loadable(
        () =>
          import(
            "./ContentPages/datenschutz/PrivacyPolicyPage/PrivacyPolicyPage"
          ),
      ),
      routeName: "privacypolicy",
      getData: getContentPageDataFactory({ contentPage: "privacypolicy" }),
    },
    {
      exact: true,
      component: loadable(
        () => import("./ReferralPages/invites/InvitePage/InvitePage"),
      ),
      routeName: "inviteLandlord",
      getData: referralInvitePageGetData,
    },
    {
      exact: true,
      component: loadable(
        () => import("./ReferralPages/landing/LandingPage/LandingPage"),
      ),
      routeName: "referralLanding",
      getData: referralLandingPageGetData,
    },
    {
      exact: true,
      routeName: "logout",
      component: loadable(() => import("./LogoutPage/LogoutPage"), {
        ssr: false,
      }),
    },
    {
      exact: true,
      routeName: "components",
      component: loadable(
        () => import("./ComponentExamplesPage/ComponentExamplesPage"),
        // We can't use ssr because ComponentExamplesPage relies on require.context
        // which is only available in client-side bundles
        { ssr: false },
      ),
    },
    {
      exact: true,
      component: loadable(() => import("./BookingsPage/LobbyPage/LobbyPage")),
      routeName: "bookingsLobby",
      getData: bookingsLobbyPageGetData,
      userIsRequired: true,
    },
    {
      exact: true,
      component: loadable(
        () => import("./BookingsPage/RequestPage/RequestPage"),
      ),
      routeName: "requestPage",
      getData: requestPageGetData,
      userIsRequired: true,
      shouldPageReload: shouldRequestPageReload,
    },
    {
      exact: true,
      component: loadable(() => import("./AuthPage/MissingPhonePage"), {
        ssr: false,
      }),
      routeName: "missingPhonePage",
      userIsRequired: true,
    },
  ];
  return parseRouteConfig(spaRoutes);
};

function parseRouteConfig(spaRoutes) {
  return spaRoutes.reduce((acc, spaRoute) => {
    let pathRes = routes[spaRoute.routeName];
    pathRes = typeof pathRes === "string" ? { en: pathRes } : pathRes;
    const getData = spaRoute.getData || (() => ({}));
    const temp = Object.entries(pathRes).map(([lang, path]) => {
      return {
        ...spaRoute,
        path,
        key: `${spaRoute.routeName}-${lang}`,
        getData,
      };
    });

    return [...acc, ...temp];
  }, []);
}
