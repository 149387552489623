import React from "react";
import PropTypes from "prop-types";
import { useCurrencies } from "../../../context/CurrenciesContext";

function ListingPriceText({ t, price, currency, lang }) {
  const { formatCurrency } = useCurrencies();
  return (
    <div className="ListingPriceText__wrapper">
      <h4 className="ListingPriceText__label">{t("prices")}</h4>

      <strong className="ListingPriceText__value">
        {formatCurrency({ amount: price, currency, lang })}{" "}
      </strong>
      <span>{t("spages.landlord.Calculator.Price.perMonth")}</span>
    </div>
  );
}

ListingPriceText.propTypes = {
  t: PropTypes.func,
  price: PropTypes.number,
  currency: PropTypes.string,
  lang: PropTypes.string,
};

export default ListingPriceText;
