import gql from "./gql";

const factory = (http) => {
  return {
    async getCurrencies() {
      const {
        data: { currencies },
      } = await http.post("/graphql", {
        query: gql`
          query currencies {
            currencies {
              isoCode
              fractionDigits
            }
          }
        `,
      });

      return currencies;
    },
  };
};

export default factory;
